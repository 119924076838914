:root {
  --blue: #0000ff;
  --white: #ffffff;
  --primary-font: Pixelify-Sans-Variable;
  --secondary-font: Roboto-Bold;
  --pink: #db48c3;
  --green: #87d96f;
  --purple: #6d53cf;

  --thumbnail-container-square-measurement: 500;
  --thumnail-end-height: 100;
  --thumbnail-body-height: 300;

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Bodoni-Moda';
  src: local('Bodoni-Moda'), url(./Fonts/Bodoni_Moda/BodoniModa-VariableFont_opsz,wght.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Pixelify-Sans-Variable';
  src: local('Pixelify-Sans-Variable'), url(./Fonts/Pixelify_Sans/PixelifySans-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(./Fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url(./Fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'pixelmix';
  src: local('pixelmix'), url(./Fonts/pixelmix/pixelmix.ttf) format('truetype');
}

@font-face {
  font-family: 'pixelmix-bold';
  src: local('pixelmix-bold'), url(./Fonts/pixelmix/pixelmix_bold.ttf) format('truetype');
}

.navbar-home-element{
  color: auto;
}

.navbar-home-element:hover{
  color: var(--pink)
}

.navbar-contact-element{
  color: auto;
}

.navbar-contact-element:hover{
  color: var(--green)
}

.navbar-about-element{
  color: auto;
}

.navbar-about-element:hover{
  color: var(--purple)
}




.page {
  background-color: var(--white);
  height: 100vh;
  width: 100vw;
  padding-top: 50px;
  padding-bottom: 50px;
}

.content-centralised-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.flex-end-modifier{
  justify-items: flex-end;
}

.flex-start-modifier{
  justify-items: flex-start;
}

.row{
  flex-direction: row;
}

.navbar-container{
  background-color: none;
  height: 130px;
  width: 100%;
  max-width: 100%;
  padding: 10px 0 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.navbar-heading{
  font-family: var(--secondary-font);
  font-size: 90px;
  font-weight: 300;
  margin: 0 0 0 0;
  text-wrap: nowrap;
  line-height: 90%;
  padding-left: 20px;
}

.navbar-option{
  font-family: var(--primary-font);
  font-size: 30px;
  font-weight: 1000;
  margin: 0 0 0 0;
  text-decoration: none;
  color: black;
}

.navbar-element-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding-top: 20px;
}

.full-width-underline{
  height: 0px;
  width: 100%;
  border-bottom: 2.8px rgb(255, 0, 0) solid;
}

.nav-links{
  list-style: none;
  padding-right: 20px;
  white-space: nowrap;
}

.nav-links li{
  display: inline-block;
}

.nav-links li + li:before{
  content: "";
  padding: 0 15px;
}

.nav-links li a{
  font-family: var(--primary-font);
  font-size: 35px;
}

.project-thumbnail-space-around-container{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.generic-flex-container{
  align-items: stretch;
  display: flex;
}

.red-container{
  border: 2.8px rgb(255, 0, 0) solid;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
}

.gap-left{
  margin-left: 2%;
}

.gap-right{
  margin-right: 2%;
}

.large-text-for-image-block{
  margin: 0 0 0 0;
  font-size: 70px;
  font-weight: bolder;
}

.paragraph{
  font-family: var(--secondary-font);
  font-size: 16px;
  margin: 0 0 0 0;  
}

.full-height{
  height: 100%;
}

.mid-sized-title{
  font-family: var(--primary-font);
  font-size: 260%;
  padding: 0 0 0 0;
  margin: 5% 0 0 0;
  color: black;
}

.thumbnail-title{
  font-family: var(--primary-font);
  font-size: 260%;
  padding: 0 0 0 0;
  margin: 3% 0 2% 0;
  color: black;
}



/* HEADER BANNER */
@keyframes slide {
  from{
    transform: translateX(-100%);
  }
  to{
    transform: translateX(0%);
  }
}

.banner-border-wrapper{
  width: 100%;
  height: 30px;
  padding-top: 4px;
  margin: 0 0 0 0;
  overflow: hidden;
  white-space: nowrap;

}

.spinner{
  display: inline-block;
  animation: 7s slide infinite linear;
}

.strings-space-between-container{
  display: flex;
}


.banner-string{
  font-family: var(--primary-font);
  font-size: 21px;
  font-weight: bolder;
  padding: 0 0 0 0;
  margin: 0px 80px 0 0;
  align-content: center;
}




/* THUMBNAIL */
@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }    
}

.thumbnail-container{
  margin-bottom: 100px;
  width: calc(var(--thumbnail-container-square-measurement) * 1px);
  display: flex;
  flex-wrap: wrap;
  /* box-shadow: 0px 10px 40px 5px; */
  border-top-left-radius: 55px;
  border-top-right-radius: 55px;
  border-bottom-left-radius: 55px;
  border-bottom-right-radius: 55px;
  animation: 2.5s floating infinite ease-in-out;
  transition: transform .5s;
  border: 3px solid black;
  overflow: hidden;
}

.thumbnail-container:hover{
  opacity: 0.8;
}

.thumbnail-top{
  width: 100%;
  background-color: black;
  height: calc(var(--thumbnail-end-height) * 1px);
  display: flex;
  place-content: center;
}

.thumbnail-body{
  height: calc(var(--thumbnail-body-height) * 1px);
  width: 100%;
  border-right: 2px solid;
  border-left: 2px solid;
}

img{
  width: 100%;
  height: 100%;
}

.thumbnail-bottom{
  width: 100%;
  background-color: black;
  height: calc(var(--thumbnail-end-height) * 1px);
  display: flex;
  place-content: center;
}

.thumbnail-description{
  max-width: 80%;
  font-weight: bold;
  font-family: var(--primary-font);
  font-size: 130%;
  text-align: justify;
  color: black;
}



/* Image Block */
.image-block-container{
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  border-top-left-radius: 55px;
  border-top-right-radius: 55px;
  border-bottom-left-radius: 55px;
  border-bottom-right-radius: 55px;
  border: 1px solid rgb(159, 159, 159);
  overflow: hidden;
  margin-top: 10px;
}


/* Project Page */
.page-title{
  animation: 2.5s floating infinite ease-in-out;
  margin: 0 0 0 0;
  font-size: 100px;
  font-family: var(--primary-font);
}

.general-block-of-text{
  font-family: var(--secondary-font);
  font-size: 20px;
  border-right: 2px black solid;
  border-left: 2px black solid;
  max-width: 70%;
  padding-right: 20px;
  padding-left: 20px;
}


/* Contact Form */
.flex-wrap-container{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
}

.input{
  border-radius: 20px;
  border: 2px black solid;
  box-shadow: 7px 7px 5px 0px rgba(0,0,0,0.75);
  width: 100%;
  padding: 0 5px 0 5px;
  font-size: 20px;
}

.input-label{
  font-family: var(--secondary-font);
  font-weight: bold;
}

.red-text{
  font-family: var(--secondary-font);
  color: red;
}

.contact-form-button{
  border-radius: 20px;
}

.contact-form-button:active{
  background-color: #898989;
}

.contact-form-body{
  width: 40%;
}

.about-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;
  width: 60%;
}


.page-bottom-spacer{
  height: 100px;
}

.page-top-spacer{
  height: 50px;
}


.footer-link{
  text-decoration: none;
  color: blue;
  font-family: var(--primary-font);
}

@media (min-width: 2600px){
  .large-text-for-image-block{
    width: 80%;
  }

  .project-thumbnail-space-around-container{
    padding: 0 5% 0 5%;
    width: 90%;
    margin-top: 50px;
  }

  .large-text-for-image-block{
    width: 80%;
  }

  .thumbnail-title{
    font-size: 35px;
  }

  .thumbnail-container{
    margin: 0 0 0 0;
    margin-bottom: 100px;
    width: calc(var(--thumbnail-container-square-measurement) * 1px);
  }

  .thumbnail-top{
    height: calc(var(--thumbnail-top-height) * 1px);
  }

  .thumbnail-bottom{
    height: calc(var(--thumbnail-top-height) * 1px);
  }

  .thumbnail-body{
    height: calc(var(--thumbnail-body-height) * 1px);
  }

  .thumbnail-description{
    font-size: 20px;
  }
}


@media (max-width: 2300px){
  .contact-form-body{
    width: 50%;
  }

  .thumbnail-title{
    font-size: 50px;
  }

  .thumbnail-container{
    width: calc(var(--thumbnail-container-square-measurement) * 1.4px);
    margin-right: 50px;
    margin-left: 50px;
  }

  .thumbnail-top{
    height: calc(var(--thumbnail-top-height) * 1.4px);
  }

  .thumbnail-bottom{
    height: calc(var(--thumbnail-top-height) * 1.4px);
  }

  .thumbnail-body{
    height: calc(var(--thumbnail-body-height) * 1.4px);
  }

  .thumbnail-description{
    font-size: 28px;
  }
}


@media (max-width: 2300px){
  .project-thumbnail-space-around-container{
    padding: 0 10% 0 10%;
    width: 80%;
    margin-top: 50px
  }
}


@media (max-width: 2050px){
  .project-thumbnail-space-around-container{
    padding: 0 5% 0 5%;
    width: 90%;
    margin-top: 50px
  }
}


@media (max-width: 2000px){
  .thumbnail-title{
    font-size: 45px;
  }

  .thumbnail-container{
    width: calc(var(--thumbnail-container-square-measurement) * 1.2px);
  }

  .thumbnail-top{
    height: calc(var(--thumbnail-top-height) * 1.2px);
  }

  .thumbnail-bottom{
    height: calc(var(--thumbnail-top-height) * 1.2px);
  }

  .thumbnail-body{
    height: calc(var(--thumbnail-body-height) * 1.2px);
  }

  .thumbnail-description{
    font-size: 25px;
  }
}


@media (max-width: 1800px){
  .contact-form-body{
    width: 50%;
  }

  .project-thumbnail-space-around-container{
    padding: 0 07.5% 0 7.5%;
    width: 85%;
    margin-top: 50px
  }
}


@media (max-width: 1662px){
  .thumbnail-container{
    margin: 0 80px 0 80px;
    margin-bottom: 100px;
  }

  .project-thumbnail-space-around-container{
    padding: 0 0 0 0;
    width: 100%;
    margin-top: 50px
  }
}


@media (max-width: 1540px){
  .project-thumbnail-space-around-container{
    padding: 0 5% 0 5%;
    width: 90%;
    margin-top: 50px
  }

  .thumbnail-container{
    margin: 0 80px 0 80px;
    margin-bottom: 100px;
  }

  .thumbnail-title{
    font-size: 40px;
  }

  .thumbnail-container{
    margin-right: 60px;
    margin-left: 60px;
    margin-bottom: 40px;
    width: calc(var(--thumbnail-container-square-measurement) * 1.1px);
  }

  .thumbnail-top{
    height: calc(var(--thumbnail-top-height) * 1.1px);
  }

  .thumbnail-bottom{
    height: calc(var(--thumbnail-top-height) * 1.1px);
  }

  .thumbnail-body{
    height: calc(var(--thumbnail-body-height) * 1.1px);
  }

  .thumbnail-description{
    font-size: 23px;
  }
}


@media (max-width: 1502px){
  .thumbnail-container{
    margin: 0 80px 0 80px;
    margin-bottom: 100px;
  }

  .project-thumbnail-space-around-container{
    padding: 0 5% 0 5%;
    width: 90%;
    margin-top: 50px
  }

  .thumbnail-container{
    margin: 0 80px 0 80px;
    margin-bottom: 100px;
  }

  .thumbnail-title{
    font-size: 38px;
  }

  .thumbnail-container{
    margin-right: 60px;
    margin-left: 60px;
    margin-bottom: 40px;
    width: calc(var(--thumbnail-container-square-measurement) * 1px);
  }

  .thumbnail-top{
    height: calc(var(--thumbnail-top-height) * 1px);
  }

  .thumbnail-bottom{
    height: calc(var(--thumbnail-top-height) * 1px);
  }

  .thumbnail-body{
    height: calc(var(--thumbnail-body-height) * 1px);
  }

  .thumbnail-description{
    font-size: 20px;
  }
}


@media (max-width: 1440px){
  .large-text-for-image-block{
    font-size: 60px;
    width: 85%
  }

  .about-container{
    width: 80%;
  }

  .about-container{
    width: 70%;
  }
}


@media (max-width: 1392px){

  .thumbnail-title{
    font-size: 34px;
  }

  .thumbnail-container{
    margin-right: 60px;
    margin-left: 60px;
    margin-bottom: 40px;
    width: calc(var(--thumbnail-container-square-measurement) * 0.9px);
  }

  .thumbnail-top{
    height: calc(var(--thumbnail-top-height) * 0.9px);
  }

  .thumbnail-bottom{
    height: calc(var(--thumbnail-top-height) * 0.9px);
  }

  .thumbnail-body{
    height: calc(var(--thumbnail-body-height) * 0.9px);
  }

  .thumbnail-description{
    font-size: 19px;
  }
}

@media (max-width: 1280px){
  .thumbnail-container{
    margin-right: 20px;
    margin-left: 20px;
  }

  .about-container{
    width: 80%;
  }

  .mid-sized-title{
    font-size: 30px;
  }
}


@media (max-width: 1200px){
  .contact-form-body{
    width: 70%;
  }

  .thumbnail-title{
    font-size: 32px;
  }

  .large-text-for-image-block{
    font-size: 50px;
  }

  .thumbnail-container{
    margin-bottom: 40px;
    margin-right: 0;
    margin-left: 0;
    width: calc(var(--thumbnail-container-square-measurement) * 0.8px);
  }

  .thumbnail-top{
    height: calc(var(--thumbnail-top-height) * 0.8px);
  }

  .thumbnail-bottom{
    height: calc(var(--thumbnail-top-height) * 0.8px);
  }

  .thumbnail-body{
    height: calc(var(--thumbnail-body-height) * 0.8px);
  }

  .thumbnail-description{
    font-size: 17px;
  }
}


@media (max-width: 1050px){
  .thumbnail-title{
    font-size: 40px;
  }

  .large-text-for-image-block{
    font-size: 50px;
  }

  .thumbnail-container{
    margin-top: 20px;
    margin-bottom: 40px;
    margin-right: 100px;
    margin-left: 100px;
    width: calc(var(--thumbnail-container-square-measurement) * 0.9px);
  }

  .thumbnail-top{
    height: calc(var(--thumbnail-top-height) * 0.9px);
  }

  .thumbnail-bottom{
    height: calc(var(--thumbnail-top-height) * 0.9px);
  }

  .thumbnail-body{
    height: calc(var(--thumbnail-body-height) * 0.9px);
  }

  .thumbnail-description{
    font-size: 19px;
  }

  .project-thumbnail-space-around-container{
    padding: 0;
    width: 100%;
    margin-top: 0
  }

  .page-title{
    font-size: 70px;
  }
}


@media (max-width: 1025px){
  .navbar-element-container{
    align-items: end;
  }

  .nav-links{
    padding: 0 20px 0 20px;
    margin: 0 0 10px 0;
    max-width: 50%;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }

  .nav-links li{
    display: flex;
  }

  .nav-links li a{
    font-family: var(--primary-font);
    font-size: 25px;
  }

  .navbar-heading{
    font-family: var(--secondary-font);
    font-size: 70px;
    font-weight: 300;
    margin: 0 0 0 0;
    text-wrap: nowrap;
    line-height: 90%;
    padding-left: 20px;
  }
}


@media (max-width: 1018px){
  .project-thumbnail-space-around-container{
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .about-container{
    width: 90%;
  }
}


@media (max-width: 837px){
  .navbar-element-container{
    align-items: end;
  }

  .large-text-for-image-block{
    font-size: 40px;
  }

  .nav-links{
    padding: 0 20px 0 20px;
    margin: 0 0 10px 0;
    max-width: 50%;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }

  .nav-links li{
    display: flex;
  }

  .nav-links li a{
    font-family: var(--primary-font);
    font-size: 25px;
  }

  .navbar-heading{
    font-family: var(--secondary-font);
    font-size: 65px;
    font-weight: 300;
    margin: 0 0 0 0;
    text-wrap: nowrap;
    line-height: 90%;
    padding-left: 20px;
  }
}


@media (max-width: 789px){
  .large-text-for-image-block{
    font-size: 35px;
  }

  .page-title{
    font-size: 60px;
  }

  .thumbnail-title{
    font-size: 40px;
  }

  .large-text-for-image-block{
    font-size: 35px;
  }

  .thumbnail-container{
    margin-top: 20px;
    margin-bottom: 40px;
    margin-right: 100px;
    margin-left: 100px;
    width: calc(var(--thumbnail-container-square-measurement) * 0.8px);
  }

  .thumbnail-top{
    height: calc(var(--thumbnail-top-height) * 0.8px);
  }

  .thumbnail-bottom{
    height: calc(var(--thumbnail-top-height) * 0.8px);
  }

  .thumbnail-body{
    height: calc(var(--thumbnail-body-height) * 0.8px);
  }

  .thumbnail-description{
    font-size: 17px;
  }
}


@media (max-width: 759px){
  .nav-links{
    margin: 0 0 7px 0;
  }

  .nav-links li a{
    font-family: var(--primary-font);
    font-size: 19px;
  }

  .navbar-heading{
    font-family: var(--secondary-font);
    font-size: 60px;
    font-weight: 300;
    margin: 0 0 0 0;
    text-wrap: nowrap;
    line-height: 90%;
    padding-left: 20px;
  }

  .mid-sized-title{
    font-size: 25px;
  }
}

@media (max-width: 671px){
  .large-text-for-image-block{
   margin-bottom: 25px; 
  }

  .nav-links{
    margin: 0 0 5px 0;
  }
  
  .nav-links li a{
    font-family: var(--primary-font);
    font-size: 17px;
  }

  .navbar-heading{
    font-family: var(--secondary-font);
    font-size: 50px;
    font-weight: 300;
    margin: 0 0 0 0;
    text-wrap: nowrap;
    line-height: 90%;
    padding-left: 20px;
  }
}

@media (max-width: 650px){
  .page-top-spacer{
    height: 30px;
  }

  .project-thumbnail-space-around-container{
    justify-content: center;
  }

  .page-title{
    font-size: 40px;
  }

  .thumbnail-title{
    font-size: 30px;
  }

  .large-text-for-image-block{
    width: 80%;
    font-size: 35px;
  }

  .thumbnail-container{
    margin-bottom: 40px;
    margin-right: 0;
    margin-left: 0;
    width: calc(var(--thumbnail-container-square-measurement) * 0.8px);
  }

  .thumbnail-top{
    height: calc(var(--thumbnail-top-height) * 0.8px);
  }

  .thumbnail-bottom{
    height: calc(var(--thumbnail-top-height) * 0.8px);
  }

  .thumbnail-body{
    height: calc(var(--thumbnail-body-height) * 0.8px);
  }

  .thumbnail-description{
    font-size: 15px;
  }
}

@media (max-width: 594px){
  .nav-links li a{
    font-family: var(--primary-font);
    font-size: 35px;
  }

  .page-bottom-spacer{
    height: 30px;
  }

  .navbar-element-container{
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  .navbar-heading{
    padding: 0 0 0 0;
    width: 100%;
    font-size: 70px;
    text-align: center;
    margin-bottom: 5px;
    margin: 5px 0 10px 0;
  }

  .nav-links li{
    display: flex;
  }

  .mid-sized-title{
    font-size: 20px;
  }

  .nav-links{
    margin: 0 0 0 0;
    max-width: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    box-sizing: border-box;
  }

  .navbar-option{
    margin-left: 10px;
    margin-right: 10px;
  }

  .large-text-for-image-block{
    text-align: center;
  }

  .navbar-container{
    width: 100%;
    max-width: 100%;
    padding: 10px 0 10px 0;
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 550px){
  .about-container{
    width: 90%;
  }
  .mid-sized-title{
    font-size: 17px;
  }

  .general-block-of-text{
    font-size: 15px;
    max-width: 80%;
  }

  .image-block-container{
    width: 90%;
  }
}



@media (max-width: 518px){
  .nav-links li a{
    font-size: 30px;
  }

  .navbar-element-container{
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  .navbar-heading{
    padding: 0 0 0 0;
    width: 100%;
    font-size: 63px;
    text-align: center;
    margin-bottom: 5px;
  }

  .nav-links li{
    display: flex;
  }

  .nav-links{
    padding: 0 15px 0 15px;
    margin: 0 0 0 0;
    max-width: 100%;
    justify-content: space-around;
    display: flex;
    margin: 0 10px;
  }

  .navbar-container{
    width: 100%;
    max-width: 100%;
    padding: 10px 0 10px 0;
    display: flex;
    flex-wrap: wrap;
  }

  .contact-form-body{
    width: 80%;
    margin-top: 20px;
  }
}

@media (max-width: 478px){
  .navbar-heading{
    font-size: 55px;

  }

  .nav-links li a{
    font-size: 28px;
  }
}

@media (max-width: 454px){
  .page-top-spacer{
    height: 20px;
  }

  .banner-string{
    font-size: 15px;
  }

  .navbar-option{
    margin: 0;
  }

  .navbar-heading{
    font-size: 50px;
    margin: 5px 0 10px 0;
  }

  .nav-links li a{
    font-size: 30px;
  }

  .large-text-for-image-block{
    font-size: 25px;
  }

  .page-title{
    font-size: 40px;
  }

  .thumbnail-title{
    font-size: 27px;
  }

  .large-text-for-image-block{
    width: calc(var(--thumbnail-container-square-measurement) * 0.7px);
  }

  .thumbnail-container{
    margin-bottom: 30px;
    width: calc(var(--thumbnail-container-square-measurement) * 0.7px);
  }

  .thumbnail-top{
    height: calc(var(--thumbnail-top-height) * 0.7px);
  }

  .thumbnail-bottom{
    height: calc(var(--thumbnail-top-height) * 0.7px);
  }

  .thumbnail-body{
    height: calc(var(--thumbnail-body-height) * 0.7px);
  }

  .thumbnail-description{
    font-size: 14px;
  }
}


@media (max-width: 408px){
  .nav-links li a{
    font-size: 25px;
  }

  .page-top-spacer{
    height: 20px;
  }

  .about-container{
    width: 95%;
  }

  .mid-sized-title{
    font-size: 15px;
  }
}


@media (max-width: 380px){
  .thumbnail-title{
    font-size: 25px;
  }

  .thumbnail-container{
    margin-bottom: 30px;
    width: calc(var(--thumbnail-container-square-measurement) * 0.62px);
  }

  .thumbnail-top{
    height: calc(var(--thumbnail-top-height) * 0.62px);
  }

  .thumbnail-bottom{
    height: calc(var(--thumbnail-top-height) * 0.62px);
  }

  .thumbnail-body{
    height: calc(var(--thumbnail-body-height) * 0.62px);
  }

  .thumbnail-description{
    font-size: 12px;
  }

  .navbar-heading{
    font-size: 46px;
  }

  .nav-links li a{
    font-size: 22px;
  }
}


@media (max-width: 365px){
  .page-top-spacer{
    height: 10px;
  }

  .general-block-of-text{
    font-size: 12px;
  }
  
  .large-text-for-image-block{
    margin-top: 10px;
    font-size: 25px;
  }

  .page-title{
    font-size: 35px;
  }

  .thumbnail-title{
    font-size: 25px;
  }

  .large-text-for-image-block{
    width: calc(var(--thumbnail-container-square-measurement) * 0.58px);;
  }

  .thumbnail-container{
    margin-bottom: 30px;
    width: calc(var(--thumbnail-container-square-measurement) * 0.58px);
  }

  .thumbnail-top{
    height: calc(var(--thumbnail-top-height) * 0.58px);
  }

  .thumbnail-bottom{
    height: calc(var(--thumbnail-top-height) * 0.58px);
  }

  .thumbnail-body{
    height: calc(var(--thumbnail-body-height) * 0.58px);
  }

  .thumbnail-description{
    font-size: 12px;
  }
}



@media (max-width: 340px){
  .navbar-heading{
    font-size: 42px;
  }

  .nav-links li a{
    font-size: 20px;
  }
}


@media (max-width: 380px){
  .large-text-for-image-block{
    font-size: 20px;
  }
}